.scrollArea :global {
  .simplebar-content {
    &::-webkit-scrollbar {
      display: none;
      width: 0;
      background: transparent;
    }
  }

  .simplebar-content-wrapper::-webkit-scrollbar {
    display: none;
  }

  .simplebar-content-wrapper {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .simplebar-visible {
    background-color: var(--color-base-60);
    border-radius: 20px;
  }

  .simplebar-scrollbar.simplebar-visible::before {
    top: -1px;
    opacity: 1;
  }

  .simplebar-track.simplebar-vertical {
    width: 4px;
    background-color: #ebebeb;
  }

  .simplebar-track.simplebar-horizontal {
    z-index: 0;
    height: 9px;
    margin: 0 20px;
  }

  .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    height: 6px;

    &::before {
      height: 100%;
    }
  }

  .simplebar-track {
    border-radius: 99px;
  }

  @media (min-width: 375px) {
    .simplebar-track.simplebar-horizontal {
      margin: 0 24px;
    }
  }
}

.scrollArea.greyScrollbar :global {
  .simplebar-track.simplebar-horizontal {
    height: 4px;
    margin: 0 14px;
  }

  .simplebar-scrollbar {
    &::before {
      background-color: #9e9e9e;
    }
  }

  .simplebar-track {
    border-radius: 20px;
  }

  .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    height: 3px;
  }
}

.scrollArea.narrowScrollbar :global {
  .simplebar-vertical {
    width: 3px;
    background-color: var(--color-base-20);
  }

  .simplebar-scrollbar::before {
    right: 0;
    left: 0;
  }
}

.scrollArea.hiddenScrollbar :global {
  .simplebar-vertical {
    display: none;
  }

  .simplebar-horizontal {
    display: none;
  }

  .simplebar-scrollbar::before {
    display: none;
  }
}
