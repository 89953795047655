.swiperWrapper {
  position: relative;

  :global(.swiper-slide) {
    opacity: 0 !important;
  }

  :global(.swiper) {
    z-index: 0 !important;
  }

  :global(.swiper-slide.swiper-slide-active) {
    opacity: 1 !important;
  }
}

.navWrap {
  position: absolute;
  z-index: 2;
  top: 3px;
  left: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 0;
}

.bulletsWrap {
  position: absolute;
  top: 9px;
  left: 4px;
  display: flex;
  gap: 8px;
}

.customBullet {
  width: 50px;
  height: 6px;
  background-color: #dedede;
  border-radius: 7px;
}

.bulletShow {
  background-color: #19b5ff;

  &.aiModificator {
    background-color: #4C6BDC;

  }
}

.bulletActive {
  overflow: hidden;

  &::after {
    display: block;
    width: 100%;
    height: 100%;
    animation: countingBar 5.3s linear;
    animation-fill-mode: forwards;
    background-color: #19b5ff;
    content: '';
    transform-origin: left;
  }

  &.aiModificator::after {
    background-color: #4C6BDC;
  }

}

.btnsWrap {
  display: none;
}

.slide.slide {
  width: max-content;
  height: auto;
}

.pagination {
  display: block;
}

.bebra {
  color: red;
}

.swiper_pagination_bullet {
  position: relative;
  width: 50px !important;
  height: 0px !important;
  margin-right: 20px;
  border-radius: 10px;
  opacity: 0 !important;

  i {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background-color: var(--color-base-20);
    border-radius: 7px;
  }
}

@keyframes countingBar {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}